import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,  } from "@angular/common/http";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private http:HttpClient){
    // Add CORS to your index.js
// const cors = require('cors')({origin: true});
// Put this line to your function
// Automatically allow cross-origin requests
// cors(req, res, () => {});
  }
  title = 'httptest';
  
  
  // createAuthorizationHeader(headers:Headers) {
  //   headers.append('Authorization', 'Basic ' +
  //   btoa('a20e6aca-ee83-44bc-8033-b41f3078c2b6:c199f9c8-0548-4be79655-7ef7d7bf9d20')); 
  // }
  
  testHttp(){
    
    // -d collection_id=inbmmepb \
    // -d description="Maecenas eu placerat ante." \
    // -d email=api@billplz.com \
    // -d name="Sara" \
    // -d amount=200 \
    // -d callback_url="http://example.com/webhook/"

    console.log('Basic ' + btoa('a2e265b9-9da2-47ed-8db7-671878a03153:'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa('a2e265b9-9da2-47ed-8db7-671878a03153:'),
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let bodyu = new HttpParams()    
    .set('collection_id', 'tfyciu0c')
    .set('email', 'quanyan90@gmail.com')
    .set('name', 'quanyan90')
    .set('amount', '300')
    .set('description', 'this is description')
    .set('callback_url', 'http://mlk.my');
    // httpOptions.headers = httpOptions.headers.set('Authorization', btoa('a2e265b9-9da2-47ed-8db7-671878a03153:'));
    // let httpHeaders = new HttpHeaders()
    // .set('authorization', btoa('a2e265b9-9da2-47ed-8db7-671878a03153:'))
    // .set('Content-Type', 'application/json'); 
    
    let body2 = new URLSearchParams()    
    body2.set('collection_id', 'tfyciu0c');
    body2.set('email', 'quanyan90@gmail.com');
    body2.set('name', 'quanyan90');
    body2.set('amount', '300');
    body2.set('description', 'this is description');
    body2.set('callback_url', 'http://mlk.my');

    let body = {
      collection_id: 'tfyciu0c',
      email: 'quanyan90@gmail.com',
      name: 'Chang Quan Yan',
      amount: 200,
      description: 'this is a test bill',
      callback_url: 'http://mlk.my'
    };


    let jsona = {
      "collection_id": "tfyciu0c",
      "email": "quanyan90@gmail.com",
      "name": "Chang Quan Yan",
      "amount": "200",
      "description": "this is a test bill",
      "callback_url": "http://mlk.my"
    };
    const params2 = new HttpParams({
      
    });
    const params = new HttpParams({
      fromObject: {
        collection_id: 'tfyciu0c',
        email: 'quanyan90@gmail.com',
        name: 'Chang Quan Yan',
        amount: '200',
        description: 'this is a test bill',
        callback_url: 'http://mlk.my'
      }
    });

    // , httpOptions
    // this.http.post('https://www.billplz-sandbox.com/api/v3/bills', params, httpOptions)
    //               .subscribe(data => {
    //                 console.log(data) // Data which is returned by call
    //               },
    //               error => { console.log(error); // Error if any
    //               });
                  console.log('end');
    // this.http.post('https://www.billplz-sandbox.com/api/v3/bills', body, httpOptions)
    // .subscribe(
    //   data => { },
    //   err => {  }
    // )
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa('a2e265b9-9da2-47ed-8db7-671878a03153:') });
  let options = { headers: headers };
   
   
    console.log(headers);
    return this.http.post('https://www.billplz-sandbox.com/api/v3/bills',params.toString() ,options)
    .subscribe(
        res =>{
            console.log(res);
        },
        err => {
            console.log(err.message);
        }
    )
  }
  // this.basic = "Basic " + btoa(this.credentials);
        // console.log(this.basic);
      
  
}
